import React from 'react';

const ResetPasswordTextField = () => {
  const handleClick = () => {
    window.open('https://iyilestir.net/auth/reset-password', '_blank');
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: "1em" }}>
      <div
        style={{
          padding: '5px 10px',
          cursor: 'pointer',
          fontSize: 12,
          color: '#00000090',
          textDecoration: 'underline'
        }}
        onClick={handleClick}
      >
        Parolamı Unuttum
      </div>
    </div>
  );
};

export default ResetPasswordTextField;