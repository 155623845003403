import { Alert, Slide, Snackbar } from "@mui/material";

function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
}
export default function CustomSnackbar({ message, isOpen, onClose }) {

    return (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={isOpen}
            autoHideDuration={2000}
            onClose={onClose}
            TransitionComponent={TransitionLeft}
        >
            <Alert severity="error">{message}</Alert>
        </Snackbar>
    )
};