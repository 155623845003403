
function isReviewData(splittedData) {
    const sub = splittedData[0];
    if (sub && sub.slice(-1) === "/") {
        return false
    }
    return true
}

/**
 * 
 * @param [] rows 
 * @return [totalList, reviewList, menuList]
 */
function prepareTableDensityData(rows) {
    const reviewList = [];
    const menuList = [];
    let totals = [];
    rows.forEach((element) => {
        const splittedData = element.dimensions[0].value.split("?");
        const name = splittedData[1].split("tag=")[1];
        const value = element.metrics[0].value;
        if (isReviewData(splittedData)) {
            reviewList.push({ name, value })
        } else {
            menuList.push({ name, value })
        }
        const sameData = totals.filter(i => i.name === name);
        if (sameData.length > 0) {
            sameData.value += value
        } else {
            totals.push({ name, value })
        }
    });
    return [totals.sort((a, b) => a.value > b.value ? 1 : -1), reviewList, menuList]
}

function prepareUserInfo(rows) {
    if(!rows || rows.length === 0)
        return [
            {
                name: "Toplam Müşteri",
                value: '-',
                rate: '-'
            },
            {
                name: "Yeni Gelen Müşteri",
                value: '-',
                rate: '-'
            },
            {
                name: "Tekrar Gelen Müşteri",
                value: '-',
                rate: '-'
            }
        ]
    let totalUser = rows[0].metrics[0].value
    let newUser = rows[0].metrics[1].value
    if(rows.length > 1) {
        totalUser += rows[1].metrics[0].value
        newUser += rows[1].metrics[1].value
    }
    const returnedUser = totalUser-newUser
    const returnedUserRate = Math.round(returnedUser * 100 / totalUser)
    const newUserRate = Math.round(newUser * 100 / totalUser)
    return [
        {
            name: "Toplam Müşteri",
            value: totalUser,
            rate: 100
        },
        {
            name: "Yeni Gelen Müşteri",
            value: newUser,
            rate: newUserRate
        },
        {
            name: "Tekrar Gelen Müşteri",
            value: returnedUser,
            rate: returnedUserRate
        }
    ]
}

function prepareUserInfoDaily(rows) {
    const ret = []
    const reviewData = []
    const menuData = []

    rows.forEach(element => {
        const pagePath = element.dimensions[0].value;
        const mmdd = element.dimensions[1].value.slice(-4)
        const dateStr = `${mmdd.slice(-2)}/${mmdd.slice(0,2)}`;
        const value = element.metrics[0].value
        const data = {
            date: dateStr,
            value
        }
        if(pagePath.slice(-1) === '/'){
            menuData.push(data)
        }
        else{
            reviewData.push(data)
        }
        const sameData = ret.filter(i => i.date === dateStr)
        if(sameData.length > 0){
            sameData[0].value += value
        }
        else {
            ret.push(data)
        }
    })
    return ret
}

function fillNonExistHours(list, day) {
    let ret = []
    let listIndex = 0
    if(list.length > 0)
    for (let i = 0; i < 24; i++) {
        let hour = ''
        if (i < 10)
            hour = `0${i}:00/${day}`;
        else 
            hour = `${i}:00/${day}`;
        if (listIndex >= list.length || hour !== list[listIndex].date) {
            ret.push({date: hour, value: 0})
        }
        else{
            ret.push(list[listIndex++])
        }
    }
    return ret
}

function prepareUserInfoHourly(rows) {
    let ret = []
    const reviewData = []
    const menuData = []

    rows.forEach(element => {
        const pagePath = element.dimensions[0].value;
        const hhdd = element.dimensions[1].value.slice(-4)
        const dateStr = `${hhdd.slice(-2)}:00/${hhdd.slice(0,2)}`;
        const value = element.metrics[0].value
        const data = {
            date: dateStr,
            value
        }
        if(pagePath.slice(-1) === '/'){
            menuData.push(data)
        }
        else{
            reviewData.push(data)
        }
        const sameData = ret.filter(i => i.date === dateStr)
        if(sameData.length > 0){
            sameData[0].value += value
        }
        else {
            ret.push(data)
        }
    })
    ret = fillNonExistHours(ret, ret[0]?.date.slice(-2))
    return ret
}

function prepareRealtimeViews(list) {
    if (!list || list.length === 0)
        return '-'
    let toplam = 0
    list.forEach(elem => {
        toplam += elem.metrics[1].value 
    })

    return toplam;
}

function prepareTotalViews(list) {
    if (!list || list.length === 0)
        return '-'
    let toplam = 0
    list.forEach(elem => {
        toplam += elem.metrics[0].value 
    })
    return toplam;
}

function preparePeriodicRatingData(list) {
    const xaxis = list[0].data.map(i => i.date)

    const firstItem = list[0].data.find( x => x && x.ratings.length > 0)
    if (!firstItem)
        return [xaxis, [{name: "-", data: xaxis.map(x => 0), totalRating: xaxis.map(x => 0)}]]
    const series = firstItem.ratings.map((val,i) => { 
        const name = val.name
        return {name, data: list[0].data.map( item => item.ratings[i]?.score ?? 0), totalRating: list[0].data.map( item => item.totalRating ?? 0)}
    });
    return {xaxis, series}
} 

export {
    isReviewData,
    prepareTableDensityData,
    prepareUserInfo,
    prepareUserInfoDaily,
    prepareUserInfoHourly,
    prepareRealtimeViews,
    prepareTotalViews,
    preparePeriodicRatingData
}