import ReactApexChart from 'react-apexcharts';

const BarChart = ({ data, tableTitle, bottomText, valueDescription, columnValuePostfix, color }) => {
    color = color ?? '#F44336'
    const options = {
        plotOptions: {
            bar: {
                dataLabels: {
                    position: "top" // top, center, bottom
                }
            }
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return Number(val).toLocaleString() + " " + (columnValuePostfix ?? '');
            },
            offsetY: -20,
            style: {
                fontSize: "12px",
                colors: ["#304758"]
            }
        },
        xaxis: {
            categories: data.map(i => i.name),
            position: "bottom",
            labels: {
                offsetY: 0,
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            crosshairs_: {
                fill: {
                    type: "gradient",
                    gradient: {
                        colorFrom: "#D8E3F0",
                        colorTo: "#BED1E6",
                        stops: [0, 100],
                        opacityFrom: 0.4,
                        opacityTo: 0.5
                    }
                }
            },
            tooltip: {
                enabled: false,
                offsetY: -35
            },
            group: {
                style: {
                    fontSize: '10px',
                    fontWeight: 700
                },
                groups: [
                    { title: bottomText, cols: data.length },
                ]
            }

        },
        fill: {
            colors: [color],
            gradient: {
                shade: "light",
                type: "horizontal",
                shadeIntensity: 0.25,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [50, 0, 100, 100]
            }
        },
        yaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            labels: {
                show: false,
                formatter: function (val) {
                    return Number(val).toLocaleString();
                }
            }
        },
        title: {
            text: tableTitle,
            floating: true,
            offsetY: 0,
            align: "center",
            style: {
                color: "#444"
            }
        },
        chart: {
            animations: {
                enabled: false
            }
        },
        series: [
            {
                name: valueDescription,
                data: data.map(i => i.value)
            }
        ]
    }

    return (
        <div id="chart">
            <ReactApexChart
                options={options}
                series={options.series}
                type="bar"
                height="300"
            />
        </div>
    );
}


export default BarChart