import '../login.css';
import React, { useRef } from 'react';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Snackbar,  TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { putRequest } from '../helpers/api-service';
import CloseIcon from '@mui/icons-material/Close';
import { BASE_URL } from '../constants/config';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }


function ChangePassword({ setIsChangePassword, isChangePassword }) {
    const oldPassword = useRef();
    const newPasswordRef = useRef();
    const rePasswordRef = useRef();
    const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
    const [showPassword1, setShowPassword1] = React.useState(false);
    const [showPassword2, setShowPassword2] = React.useState(false);
    const [showPassword3, setShowPassword3] = React.useState(false);

    function sendPasswordChange() {
        const password = oldPassword.current.value;
        const newPassword = newPasswordRef.current.value;
        const rePassword = rePasswordRef.current.value;
        const putData = { password, newPassword, rePassword };
        putRequest(BASE_URL + "/app/update/ChangePassword", putData).then(res => {
            if (res.statusCode === 1000) {
                setIsChangePassword(false);
            } else {
                //console.log(res)
                setOpenErrorSnackbar(true);
            }
        })

    }

    function TransitionLeft(props) {
        return <Slide {...props} direction="left" />;
    }

    const handleClickShowPassword = (which) => {
        return () => {
            switch (which) {
                case 1:
                    setShowPassword1(!showPassword1)
                    break;
                case 2:
                    setShowPassword2(!showPassword2)
                    break;
                case 3:
                    setShowPassword3(!showPassword3)
                    break;

                default:
                    break;
            }
        }
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div className=''>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={openErrorSnackbar}
                autoHideDuration={2000}
                onClose={() => setOpenErrorSnackbar(false)}
                TransitionComponent={TransitionLeft}
            >
                <Alert severity="error">Şifre değiştirme başarısız!</Alert>
            </Snackbar>
            <Dialog
                open={isChangePassword}
                TransitionComponent={Transition}
                keepMounted

                onClose={() => setIsChangePassword(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <BootstrapDialogTitle
                    onClose={() => setIsChangePassword(false)}
                >{"Şifre Değiştir"}
                </BootstrapDialogTitle>
                <DialogContent>
                    <form className='reset-password-form'>
                        <TextField
                            InputProps={{ // <-- This is where the toggle button is added.
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword(1)}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword1 ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            id="filled-basic1"
                            className='custom-reset-password-input password-input'
                            label="Parola"
                            type={showPassword1 ? "text" : "password"}
                            variant="filled"
                            inputRef={oldPassword}
                        />
                        <TextField
                            InputProps={{ // <-- This is where the toggle button is added.
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword(2)}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword2 ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            id="filled-basic2"
                            className='custom-reset-password-input password-input'
                            label="Yeni Parola"
                            type={showPassword2 ? "text" : "password"}
                            variant="filled"
                            inputRef={newPasswordRef}
                        />
                        <TextField
                            InputProps={{ // <-- This is where the toggle button is added.
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword(3)}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword3 ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            id="filled-basic3"
                            className='custom-reset-password-input last-password-input'
                            label="Tekrar Yeni Parola"
                            type={showPassword3 ? "text" : "password"}
                            variant="filled"
                            inputRef={rePasswordRef}
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button className='sign-in-button' onClick={sendPasswordChange}>Değiştir</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ChangePassword;
