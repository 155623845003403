import '../login.css';
import React from 'react';
import { Dialog, DialogContent, DialogTitle, Slide } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { reviewContent } from '../helpers/reviews';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

function UserReviewsPopup({ setIsOpenUserReviews, isOpenUserReviews, userReviews, sourceCreateDate, otherReviewsOnClick }) {
    return (
        <div className=''>
            <Dialog
                className='user-reviews-popup'
                open={isOpenUserReviews}
                TransitionComponent={Transition}
                keepMounted

                onClose={() => setIsOpenUserReviews(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <BootstrapDialogTitle
                    onClose={() => setIsOpenUserReviews(false)}
                >{"Kullanıcı Yorumları"}
                </BootstrapDialogTitle>
                <DialogContent>
                    {
                        userReviews && userReviews.map(item => {
                            return (
                                reviewContent(item, sourceCreateDate, otherReviewsOnClick)
                            )
                        })
                    }
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default UserReviewsPopup;