import { format, addHours, subDays } from "date-fns";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import StarFilled from '../assets/images/star-filled.svg';
import GetirLogo from '../assets/images/Getir_Logo_1621812382342.png';
import YemekSepetiLogo from '../assets/images/yemeksepeti-logo.svg';
import GoogleLogo from '../assets/images/google-logo.svg';
import IyilestirBlackLogo from '../assets/images/iyilestir_svg.svg';

export function reviewContent(item, sourceCreateDate, otherReviewsOnClick) {
    if (!item) return null;
    return (
        <div className='review-card-wrapper'>
            <div className='source-body-wrapper'>
                <div className='review-card'>
                    <div className='review-header'>
                        <div className='review-name-surname-wrapper'>
                            <div className='name-surname-text'>{item.authorName ?? "Anonim"}</div>
                            {item.clientReviewCount &&
                                <div onClick={() => { otherReviewsOnClick(item) }} className="client-review-count-wrapper">
                                    <div className='user-reviews-seperator dot'></div>
                                    <div className='user-reviews-count'>{item.clientReviewCount} yorum daha</div>
                                </div>
                            }

                        </div>
                        <div title='Değerlendirme' className='review-average-rating'>
                            <img alt='averageRating' className='source-desc-image source-star-filled' src={StarFilled} />
                            {(item.rating ? Number(item.rating).toFixed(1) : "-") + "/" + rateHandler(item.source)}
                        </div>
                    </div>
                    <div className='review-content-text' dangerouslySetInnerHTML={{ __html: item.comment }}></div>
                    <div className='review-date'>{dateTextHandler(item, sourceCreateDate)}</div>
                </div>
                <div className={`source-logo-wrapper ${item.link && "clickable-review-card-wrapper"}`} onClick={() => item.link && window.open(item.link, "_blank")}>
                    {
                        item.source === 0 ?
                            <img alt='averageRating' className='source-logo getir-logo' src={GetirLogo} />
                            : item.source === 1 ?
                                <img alt='averageRating' className='source-logo yemeksepeti-logo' src={YemekSepetiLogo} />
                                : item.source === 2 ?
                                    <img alt='averageRating' className='source-logo yemeksepeti-logo' src={GoogleLogo} />
                                    : <img alt='averageRating' className='source-logo iyilestir-logo' src={IyilestirBlackLogo} />
                    }
                </div>
                {item.link && <div className='clickable-item'>
                    <ChevronRightIcon className='clickable-item-icon' />
                </div>
                }
            </div>
            <div className='horizontal-line'></div>
        </div >
    )
}

function rateHandler(source) {
    return source === 1 ? 10 : 5
}

function dateTextHandler(item, sourceCreateDate) {
    let createDate = new Date(item.createDate)
    try {
        if (item.source === 0) {
            createDate = subDays(new Date(item.rangeEndDate), 1)
            if (new Date(sourceCreateDate) < createDate) {
                createDate = format(createDate, "dd-MM-yyyy")
            } else {
                createDate = format(sourceCreateDate, "dd-MM-yyyy") + " Tarihinden önce"
            }
        } else if (item.source === 1) {
            createDate = format(addHours(new Date(item.createdAt), 3), "dd-MM-yyyy HH:mm:ss")
        } else if (item.source === 2) {
            if (item.rangeStartDate === item.rangeEndDate) {
                createDate = format(new Date(item.rangeStartDate), "dd-MM-yyyy")
            } else {
                createDate = format(new Date(item.rangeStartDate), "dd-MM-yyyy") + " - " + format(new Date(item.rangeEndDate), "dd-MM-yyyy")
            }
        } else {
            createDate = format(createDate, "dd-MM-yyyy HH:mm:ss")
        }
        return createDate
    } catch (error) {
        console.log(item.source + " +++++ " + item.createDate + "\n\n" + error.message + "\n\n" + error.stack)
    }
}

export const findMostFrequent = (str = '', num = 4) => {
    const strArr = str.split(' ');
    const map = {};
    strArr.forEach(word => {
        if (map.hasOwnProperty(word)) {
            map[word]++;
        } else {
            map[word] = 1;
        }
    });
    const frequencyArr = Object.keys(map).map(key => [key, map[key]]);
    frequencyArr.sort((a, b) => b[1] - a[1]);
    return frequencyArr.slice(0, num).map(el => el[0]);
};