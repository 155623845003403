import '../styles/report-error.css';
import React, { useRef } from 'react';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Snackbar, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { contactWithUs } from '../helpers/api-service';
import CloseIcon from '@mui/icons-material/Close';
import CustomSnackbar from '../components/custom-snackbar';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}


function ReportError({ setIsReportError, isReportError, userData }) {
    const nameRef = useRef();
    const surnameRef = useRef();
    const phoneRef = useRef();
    const subjectRef = useRef();
    const descriptionRef = useRef();
    const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);

    function sendReport() {
        const name = nameRef.current.value;
        const surname = surnameRef.current.value;
        const phone = phoneRef.current.value;
        const subject = subjectRef.current.value;
        const description = descriptionRef.current.value;
        if (!name || !surname || !phone || !subject || !description) {
            setOpenErrorSnackbar(true);
            return;
        }


        const data = {
            name,
            surname,
            phone,
            subject,
            description,
            email: userData?.email ?? 'yok@email.com'
        }

        contactWithUs(data).then(res => {
            if (res.statusCode === 1000) {
                setIsReportError(false)
                name.current.value = null
                surname.current.value = null
                phone.current.value = null
                subject.current.value = null
                description.current.value = null
            }
            else {
                setOpenErrorSnackbar(true)
            }

        })
    }

    return (
        <div className=''>
            <CustomSnackbar
                isOpen={openErrorSnackbar}
                onClose={() => setOpenErrorSnackbar(false)}
                message="Lütfen tüm alanları doldurun!"
            >
            </CustomSnackbar>
            <Dialog
                open={isReportError}
                TransitionComponent={Transition}
                keepMounted

                onClose={() => setIsReportError(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <BootstrapDialogTitle
                    onClose={() => setIsReportError(false)}
                >{"Sorun Bildir"}
                </BootstrapDialogTitle>
                <DialogContent>
                    <form>
                        <div className='report-error-text-field-div report-error-text-field-row'>
                            <TextField className='report-error-text-field'
                                required
                                label="İsim"
                                margin='normal'
                                inputRef={nameRef}
                            />
                            <TextField className='report-error-text-field'
                                required
                                label="Soyisim"
                                margin='normal'
                                inputRef={surnameRef}
                            />
                        </div>
                        <div className='report-error-text-field-div report-error-text-field-row'>
                            <TextField className='report-error-text-field'
                                required
                                label="Telefon"
                                margin='normal'
                                inputRef={phoneRef}
                            />
                            <TextField className='report-error-text-field'
                                required
                                label="Konu"
                                margin='normal'
                                inputRef={subjectRef}
                            />
                        </div>
                        <div className='report-error-text-field-div'>
                            <TextField className='report-error-text-field'
                                label="Açıklama"
                                multiline
                                rows={4}
                                fullWidth
                                margin='normal'
                                inputRef={descriptionRef}
                                required
                            />
                        </div>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button className='sign-in-button' type='submit' onClick={sendReport}>Gönder</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ReportError;
