import '../App.css';
import "react-datepicker/dist/react-datepicker.css";
import React, { useCallback, useEffect, useRef } from "react";

import { Checkbox, FormControlLabel, FormGroup, Paper, Slider, Skeleton, Button } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { addHours } from "date-fns";

import StarFilled from '../assets/images/star-filled.svg';
import CommentFilled from '../assets/images/comment.svg';
import StarsFilled from '../assets/images/stars.svg';
import CommentFilledBlack from '../assets/images/comment-black.svg';
import CourierLogo from '../assets/images/courier.svg';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';

import UserReviewsPopup from '../pages/userReviewsPopup';
import { reviewContent } from '../helpers/reviews';
import { clientReviewsWithCompanyRequest } from '../helpers/api-service';
import { useNavigate } from 'react-router-dom';
import { getComboboxOption } from '../helpers/company-helper';
import { getOptions, getPostOptions } from '../constants/apiConstants';
import { VariableSizeList as List } from 'react-window';
import { useWindowResize } from "./useWindowResize";
import SearchBox from '../components/search-box';
import { CustomDatePicker } from '../components/custom-date-picker';
import { useLocalStorage } from '../helpers/helper';
import { BASE_URL } from '../constants/config';

function MainPage() {
    const navigate = useNavigate();

    const [isOpenUserReviews, setIsOpenUserReviews] = React.useState(false);
    const [getirData, setGetirData] = React.useState();
    const [yemekSepetiData, setYemekSepetiData] = React.useState();
    const [googleMapsData, setGoogleMapsData] = React.useState();
    const [sikayetVarData, setSikayetVarData] = React.useState();
    const [iyilestirData, setIyilestirData] = React.useState();
    const [newIyilestirData, setNewIyilestirData] = React.useState();
    const [newIyilestirMetrics, setNewIyilestirMetrics] = React.useState();
    const [reviews, setReviews] = React.useState([]);
    const [filteredReviews, setFilteredReviews] = React.useState([]);
    const [selectedSources, setSelectedSources] = React.useState([0, 1, 2, 3, 4]);
    const [getirRange, setGetirRange] = React.useState([1, 5]);
    const [yemekSepetiRange, setYemekSepetiRange] = React.useState([1, 10]);
    const [googleRange, setGoogleRange] = React.useState([1, 5]);
    const [iyilestirRange, setIyilestirRange] = React.useState([1, 5]);
    const [dateRange, setDateRange] = React.useState([null, null]);
    const [sourceCreateDate, setSourceCreateDate] = React.useState();
    const [sourceLoading, setSourceLoading] = React.useState(true);
    const [reviewLoading, setReviewLoading] = React.useState(true);
    const [onlyCommentsSelected, setOnlyCommentsSelected] = React.useState(false);
    const [userReviews, setUserReviews] = React.useState();
    const [searchTerm, setSearchTerm] = React.useState("");
    const [delayedSearchEvent, setDelayedSearchEvent] = React.useState(0);
    const [showSearchCircularProgress, setShowSearchCircularProgress] = React.useState(false);
    const [isDateDialogOpen, setIsDateDialogOpen] = React.useState(false);
    let params = new URLSearchParams(window.location.search);
    let subdomainQueryParam = params.get('subdomain');
    const [token] = useLocalStorage('iyilestir-review-token', null);

    let subdomain;
    let userData;

    const fetchData = async () => {
        subdomain = subdomainQueryParam ?? localStorage.getItem("iyilestir-subdomain")
        userData = localStorage.getItem("iyilestir-user")
        setSourceLoading(true);
        setReviewLoading(true);
        let list = [];
        const options = getOptions();
        const option = {
            headers: options
        };
        const fetchExternalReviews = async () => {
            try {
                const response = await fetch(BASE_URL + "/app/get/GetExternalReviews?title=" + subdomain, option);
                return await response.json();
            } catch (error) { }
        }
        const fetchIyilestirMetrics = async () => {
            try {
                const response = await fetch(BASE_URL + "/app/get/metrics?metric=FTTM1&locale=tr", option);
                return await response.json();
            } catch (error) { }
        }
        const fetchIyilestir = async () => {
            try {
                const data = {
                    "requestModel": {
                        "title": subdomain,
                        "startDate": "2010-10-02"
                    }
                };
                return await fetch(BASE_URL + "/app/insert/IyilestirOldReviews",
                    {
                        method: 'POST',
                        headers: getPostOptions(),
                        body: JSON.stringify(data)
                    }) //" + companyTitle + "?startDate=2010-11-02")
            } catch (error) { }
        }
        const fetchNewVersionOfIyilestir = async () => {
            try {
                const response = await fetch(BASE_URL + "/app/get/GetFeedbackAnswers?limit=@number:99999999&skip=@number:0", option);
                return await response?.json();
            } catch (error) { }
        }

        let [data, iyilestirResJson, newIyilestirDatas, iyilestirMetrics] = await Promise.all([fetchExternalReviews(), fetchIyilestir(), fetchNewVersionOfIyilestir(), fetchIyilestirMetrics()])
        setNewIyilestirMetrics(iyilestirMetrics.responseModel?.length ? iyilestirMetrics.responseModel[0] : null);

        let getirReviewList = [];
        let yemekSepetiReviewList = [];
        let googleMapsReviewList = [];
        let sikayetVarReviewList = [];
        let iyilestirReviewList = [];
        let newIyilestirReviewList = [];

        if (iyilestirResJson) {
            const iyilestirRes = (await iyilestirResJson.json()).responseModel;
            if (iyilestirRes && iyilestirRes.reviewsObj) {
                iyilestirRes.reviewsObj.forEach(element => {
                    element.source = 3;
                    element.sortDate = element.createDate
                });
                iyilestirReviewList = iyilestirRes.reviewsObj;
            }
            setIyilestirData(iyilestirRes)
        }

        if (newIyilestirDatas && newIyilestirDatas.statusCode === 1000 && newIyilestirDatas.responseModel && newIyilestirDatas.responseModel.length > 0) {
            newIyilestirReviewList = newIyilestirDatas.responseModel;
            newIyilestirReviewList.forEach(element => {
                element.source = 4;
                element.sortDate = element.createDate
                const { nameSurname, email, phone } = element.customerObj;
                element.authorName = nameSurname ?? email
                element.comment = phone ? `<a href="tel:${phone}"><b>Telefon: </b>${phone}</a>\n` : "";
                const rateList = [];
                element.questionsObj.forEach((question, index) => {
                    const questionTitle = question.shortTitle ?? question.title;
                    const answer = element.answersObj[index]
                    const isRatedQuestion = answer.rate !== null && answer.rate !== undefined
                    let answerComment = isRatedQuestion ? answer.rate : answer.questionContentObj.title
                    if (isRatedQuestion) {
                        answerComment = answer.rate;
                        rateList.push(answerComment);
                    } else {
                        answerComment = answer.questionContentObj.title ?? answer.description
                    }
                    element.comment += `<b>${questionTitle}</b>: ${answerComment ?? "-"}`
                    if (index + 1 !== element.questionsObj.length) {
                        element.comment += "\n";
                    } else {
                        element.rating = rateList.reduce((a, b) => a + b, 0) / rateList.length;
                    }
                })
            });
            setNewIyilestirData(newIyilestirReviewList);
        }

        if (data.responseModel.length) {
            const getirDatas = data.responseModel.filter(d => (d.source === 0))[0];
            const yemekSepetiDatas = data.responseModel.filter(d => (d.source === 1))[0];
            const googleMapsDatas = data.responseModel.filter(d => (d.source === 2))[0];
            const sikayetVarDatas = data.responseModel.filter(d => (d.source === "4"))[0]; // daha yok
            setSourceCreateDate(getirData?.sourceCreateDate ? new Date(getirData?.sourceCreateDate) : null);

            setGetirData(getirDatas);
            setYemekSepetiData(yemekSepetiDatas);
            setGoogleMapsData(googleMapsDatas);
            setSikayetVarData(sikayetVarDatas);

            getirReviewList = getirDatas ? getirDatas.reviewsObj.filter(i => i.sortDate = i.createDate) : [];
            yemekSepetiReviewList = yemekSepetiDatas ? yemekSepetiDatas.reviewsObj.filter(i => i.sortDate = i.createdAt) : [];
            googleMapsReviewList = googleMapsDatas ? googleMapsDatas.reviewsObj.filter(i => i.sortDate = i.rangeStartDate ?? i.createDate) : [];
            sikayetVarReviewList = sikayetVarDatas ? sikayetVarDatas.reviewsObj.filter(i => i.sortDate = i.createdAt) : [];
        }
        list = [...getirReviewList, ...yemekSepetiReviewList, ...googleMapsReviewList, ...sikayetVarReviewList, ...iyilestirReviewList, ...newIyilestirReviewList]

        setReviews(list.sort(function (a, b) {
            return new Date(a.sortDate) - new Date(b.sortDate)
        }))

        setSourceLoading(false);
        setReviewLoading(false);
    }

    useEffect(() => {
        if (token) {
            fetchData();
        }
    }, [token]);

    React.useEffect(() => {
        chbOnChange();
    }, [getirRange, yemekSepetiRange, googleRange, iyilestirRange])

    React.useEffect(() => {
        if ((dateRange[0] && dateRange[1]) || (!dateRange[0] && !dateRange[1])) {
            chbOnChange();
        }
    }, [dateRange])

    React.useEffect(() => {
        chbOnChange();
    }, [reviews, onlyCommentsSelected, delayedSearchEvent])

    React.useEffect(() => {
        if (searchTerm.length > 0 && searchTerm.length < 3) {
            setShowSearchCircularProgress(false);
            return;
        }
        const delayDebounceFn = setTimeout(() => {
            setDelayedSearchEvent(delayedSearchEvent + 1);
            setShowSearchCircularProgress(false);
        }, 2000);

        setShowSearchCircularProgress(true);
        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    function chbOnChange(val) {
        let selectedS = selectedSources;
        if (val) {
            if (Array.isArray(val)) {
                if (selectedSources.some(i => val.some(a => a === i))) {
                    selectedS = selectedSources.filter(function (i) { return !val.some(a => a === i) });
                } else {
                    selectedS.push(...val);
                }
                setSelectedSources(selectedS);
            } else {
                if (selectedSources.some(i => i === val)) {
                    selectedS = selectedSources.filter(function (i) { return i !== val });
                } else {
                    selectedS.push(val);
                }
            }
            setSelectedSources(selectedS);
        }
        const endDate = dateRange[1];
        const startDate = dateRange[0];
        let allList = [];
        if (endDate && startDate) {
            allList = reviews.filter(i =>
                selectedS.some(a => a === i.source) &&
                new Date(i.sortDate) < addHours(new Date(endDate), 24) &&
                new Date(i.sortDate) >= new Date(startDate)
            );
        } else {
            allList = reviews.filter(i => selectedS.some(a => a === i.source));
        }

        let newIyilestirList = allList.filter(i => i.source === 4 && ((iyilestirRange[1] >= i.rating && i.rating >= iyilestirRange[0]) || !i.rating));
        let iyilestirList = allList.filter(i => i.source === 3 && iyilestirRange[1] >= i.rating && i.rating >= iyilestirRange[0]);
        let getirList = allList.filter(i => i.source === 0 && getirRange[1] >= i.rating && i.rating >= getirRange[0]);
        let yemekSepetiList = allList.filter(i => i.source === 1 && yemekSepetiRange[1] >= i.rating && i.rating >= yemekSepetiRange[0]);
        let googleList = allList.filter(i => i.source === 2 && googleRange[1] >= i.rating && i.rating >= googleRange[0]);
        if (onlyCommentsSelected) {
            iyilestirList = iyilestirList.filter(i => i.comment.includes('<b>Görüşler:</b>'))
            newIyilestirList = newIyilestirList.filter(i => i.answersObj.some(i => i.description))
        }
        if (searchTerm !== '' && searchTerm.length > 2) {
            const searchFor = searchTerm.toLowerCase();
            newIyilestirList = newIyilestirList.filter(i => i.answersObj.some(i => i.questionContentObj?.title?.toLowerCase().includes(searchFor)))
            iyilestirList = iyilestirList.filter(i => i.comment.toLowerCase().includes(searchFor))
            getirList = getirList.filter(i => i.comment.toLowerCase().includes(searchFor))
            yemekSepetiList = yemekSepetiList.filter(i => i.comment.toLowerCase().includes(searchFor))
            googleList = googleList.filter(i => i.comment.toLowerCase().includes(searchFor))
        }

        setFilteredReviews([...iyilestirList, ...getirList, ...yemekSepetiList, ...googleList, ...newIyilestirList].sort(function (a, b) {
            return new Date(b.sortDate) - new Date(a.sortDate)
        }))
    }


    function isIyilestirChecked() {
        return selectedSources.some(i => i === 3 || i === 4)
    }

    function isGetirChecked() {
        return selectedSources.some(i => i === 0)
    }
    function isYemekSepetiChecked() {
        return selectedSources.some(i => i === 1)
    }
    function isGoogleChecked() {
        return selectedSources.some(i => i === 2)
    }

    function isOnlyCommentsChecked(event) {
        setOnlyCommentsSelected(event.target.checked);
    }

    const handleIyilestirChange = (event, newValue) => {
        setIyilestirRange(newValue);
    };

    const handleGetirChange = (event, newValue) => {
        setGetirRange(newValue);
    };

    const handleYemekSepetiChange = (event, newValue) => {
        setYemekSepetiRange(newValue);

    };

    const handleGoogleChange = (event, newValue) => {
        setGoogleRange(newValue);
    };

    function renderGetirCard() {
        if (!getirData) return null;
        return (
            <Paper className='source-paper source-card source-getir'><a className='source-card-wrapper' href={getirData.url} target="_blank" rel="noreferrer">
                <div className='source-title'>Getir</div>
                <div>
                    <div title='Toplam Değerlendirme Sayısı' className='source-desc total-rating-count'>
                        <img alt='totalReviewCount' className='source-desc-image source-star-filled' src={StarsFilled} />
                        {getirData.totalRatingCount ?? 0}
                    </div>
                    <div title='Toplam Yorum Sayısı' className='source-desc total-review-count'>
                        <img alt='totalReviewCount' className='source-desc-image source-star-filled' src={CommentFilled} />
                        {getirData.totalReviewCount ?? 0}
                    </div>
                    <div title='Ortalama Değerlendirme' className='source-desc average-rating'>
                        <img alt='averageRating' className='source-desc-image source-star-filled' src={StarFilled} />
                        {(getirData.averageRating ?? 0) + "/5"}
                    </div>
                    <div title='Kurye Değerlendirmesi' className='source-desc courier'>
                        <img alt='courier' className='source-desc-image courier-filled' src={CourierLogo} />
                        {(getirData.courierRating ?? 0) + "/5"}
                    </div>
                </div>
            </a>
            </Paper>
        )
    }
    function renderYemekSepetiCard() {
        if (!yemekSepetiData) return null;
        return (
            <Paper className='source-paper source-card source-yemeksepeti'><a className='source-card-wrapper' href={yemekSepetiData.url} target="_blank" rel="noreferrer">
                <div className='source-title'>Yemek Sepeti</div>
                <div title='Toplam Değerlendirme Sayısı' className='source-desc total-rating-count'>
                    <img alt='totalReviewCount' className='source-desc-image source-star-filled' src={StarsFilled} />
                    {yemekSepetiData.totalRatingCount ?? 0}
                </div>
                <div title='Toplam Yorum Sayısı' className='source-desc total-review-count'>
                    <img alt='totalReviewCount' className='source-desc-image source-star-filled' src={CommentFilled} />
                    {yemekSepetiData.totalReviewCount ?? 0}
                </div>
                <div title='Ortalama Değerlendirme' className='source-desc average-rating'>
                    <img alt='averageRating' className='source-desc-image source-star-filled' src={StarFilled} />
                    {(yemekSepetiData.averageRating ?? 0) + "/10"}
                </div>
            </a>
            </Paper>
        )
    }

    function renderGoogleCard() {
        if (!googleMapsData) return null;
        return (
            <Paper className='source-paper source-card source-googleplace'><a className='source-card-wrapper' href={googleMapsData.url} target="_blank" rel="noreferrer">
                <div className='source-title'>Google Maps</div>
                <div title='Toplam Değerlendirme Sayısı' className='source-desc total-rating-count'>
                    <img alt='totalReviewCount' className='source-desc-image source-star-filled' src={StarsFilled} />
                    {googleMapsData.totalRatingCount ?? 0}
                </div>
                <div title='Toplam Yorum Sayısı' className='source-desc total-review-count'>
                    <img alt='totalReviewCount' className='source-desc-image source-star-filled' src={CommentFilled} />
                    {googleMapsData.totalReviewCount ?? 0}
                </div>
                <div title='Ortalama Değerlendirme' className='source-desc average-rating'>
                    <img alt='averageRating' className='source-desc-image source-star-filled' src={StarFilled} />
                    {(googleMapsData.averageRating ?? 0) + "/5"}
                </div>
            </a>
            </Paper>
        )
    }
    function renderSikayetVarCard() {
        return (
            sikayetVarData ?
                <a className='source-card-wrapper' href={sikayetVarData.url} target="_blank" rel="noreferrer">
                    <Paper className='source-paper'>
                        <div className='source-card source-sikayetvar'>
                            <div className='source-title'>Şikayet Var</div>
                            <div title='Toplam Değerlendirme Sayısı' className='source-desc total-rating-count'>
                                <img alt='totalReviewCount' className='source-desc-image source-star-filled' src={StarsFilled} />
                                {sikayetVarData.totalRatingCount ?? 0}
                            </div>
                            <div title='Toplam Yorum Sayısı' className='source-desc total-review-count'>
                                <img alt='totalReviewCount' className='source-desc-image source-star-filled' src={CommentFilled} />
                                {sikayetVarData.totalReviewCount ?? 0}
                            </div>
                            <div title='Ortalama Değerlendirme' className='source-desc average-rating'>
                                <img alt='averageRating' className='source-desc-image source-star-filled' src={StarFilled} />
                                {(sikayetVarData.averageRating ?? 0) + "/5"}
                            </div>
                        </div>
                    </Paper>
                </a>
                :
                <Paper className='source-paper source-card source-sikayetvar'>
                    <div className='source-card-wrapper' target="_blank" rel="noreferrer">
                        <div className='source-title'>Şikayet Var (Yakında)</div>
                        <div title='Toplam Değerlendirme Sayısı' className='source-desc total-rating-count'>
                            <img alt='totalReviewCount' className='source-desc-image source-star-filled' src={StarsFilled} />
                            {0}
                        </div>
                        <div title='Toplam Yorum Sayısı' className='source-desc total-review-count'>
                            <img alt='totalReviewCount' className='source-desc-image source-star-filled' src={CommentFilled} />
                            {0}
                        </div>
                        <div title='Ortalama Değerlendirme' className='source-desc average-rating'>
                            <img alt='averageRating' className='source-desc-image source-star-filled' src={StarFilled} />
                            {0}
                        </div>
                    </div>
                </Paper>
        )
    }

    function renderIyilestirCard() {
        if (!iyilestirData && !newIyilestirMetrics) return null;
        const totalReviewCount = Number(newIyilestirMetrics?.totalReviewCount ?? 0) + Number(iyilestirData?.totalReviewCount ?? 0)
        const totalRatingCount = Number(newIyilestirMetrics?.totalRatingCount ?? 0) + Number(iyilestirData?.totalRatingCount ?? 0)
        const totalAverageRating = ((Number(newIyilestirMetrics?.averageRating ?? 0) * newIyilestirMetrics?.totalRatingCount ?? 0) + (Number(iyilestirData?.averageRating ?? 0) * Number(iyilestirData?.totalRatingCount ?? 0))) / totalRatingCount
        const averageRating = totalAverageRating ? (Number(totalAverageRating)).toFixed(1) : 0
        return (
            <Paper className='source-paper source-card source-iyilestir'>
                <div className='source-title'>İyileştir</div>
                <div title='Toplam Değerlendirme Sayısı' className='source-desc total-rating-count'>
                    <img alt='totalReviewCount' className='source-desc-image source-star-filled' src={StarsFilled} />
                    {totalRatingCount}
                </div>
                <div title='Toplam Yorum Sayısı' className='source-desc total-review-count'>
                    <img alt='totalReviewCount' className='source-desc-image source-star-filled' src={CommentFilled} />
                    {totalReviewCount}
                </div>
                <div title='Ortalama Değerlendirme' className='source-desc average-rating'>
                    <img alt='averageRating' className='source-desc-image source-star-filled' src={StarFilled} />
                    {averageRating + "/5"}
                </div>
            </Paper>
        )
    }

    function getCarouselList() {
        const list = [];
        if (getirData) {
            list.push(renderGetirCard())
        }
        if (yemekSepetiData) {
            list.push(renderYemekSepetiCard())
        }
        if (googleMapsData) {
            list.push(renderGoogleCard())
        }
        if (iyilestirData) {
            list.push(renderIyilestirCard())
        }
        // list.push(renderSikayetVarCard())
        return list
    }

    function renderDatePicker() {
        return (
            <div className='date-picker-wrapper'>
                <CustomDatePicker
                    isOpen={isDateDialogOpen}
                    setIsOpen={setIsDateDialogOpen}
                    onApprove={(update) => { setDateRange(update); }}
                />
            </div>
        )
    }

    const sourceCardSkeleton = () => {
        return (
            <Paper className="source-paper source-card" sx={{ background: "#808080" }}>
                <Skeleton variant="text" width={"100%"} height={40} sx={{ fontSize: '1rem' }} />
                <div className='skeleton-row'>
                    <img alt='totalReviewCount' className='source-desc-image source-star-filled' src={StarsFilled} />
                    <Skeleton width={"30px"} className='source-desc total-rating-count' />
                </div>

                <div className='skeleton-row'>
                    <img alt='totalReviewCount' className='source-desc-image source-star-filled' src={CommentFilled} />
                    <Skeleton width={"30px"} className='source-desc total-rating-count' />
                </div>

                <div className='skeleton-row'>
                    <img alt='totalReviewCount' className='source-desc-image source-star-filled' src={StarFilled} />
                    <Skeleton width={"30px"} className='source-desc total-rating-count' />
                </div>

                <div className='skeleton-row'>
                    <img alt='courier' className='source-desc-image source-star-filled' src={CourierLogo} />
                    <Skeleton width={"30px"} className='source-desc total-rating-count' />
                </div>
            </Paper >
        )
    }

    const reviewItemSkeleton = () => {
        return (
            <div className='review-card-wrapper'>
                <div className='source-body-wrapper'>
                    <div className='review-card'>
                        <div className='review-header'>
                            <Skeleton className='name-surname-text' variant="text" width={"150px"} height={30} ></Skeleton>
                            <div className='skeleton-row'>
                                <img alt='averageRating' className='source-desc-image source-star-filled' src={StarFilled} />
                                <Skeleton className='review-average-rating' variant="text" sx={{ marginRight: "10px" }} width={"50px"} height={30} ></Skeleton>
                            </div>
                        </div>
                        <Skeleton className='review-content-text' variant="text" width={"80%"} height={20} ></Skeleton>
                        <Skeleton className='review-content-text' variant="text" width={"40%"} height={20} ></Skeleton>
                        <div className='review-date'>
                            <Skeleton variant="text" width={"120px"} height={15} ></Skeleton>
                        </div>
                    </div>
                    <div className={`source-logo-wrapper clickable-review-card-wrapper`} >
                        {
                            <Skeleton width="40px" height="40px" sx={{ borderRadius: "1000px" }} />
                        }
                    </div>
                </div>
                <div className='horizontal-line'></div>
            </div >
        )
    }

    async function otherReviewsOnClick(item) {
        const subdomain = localStorage.getItem("iyilestir-subdomain")
        const clientId = item.clientId;
        const uReviews = await clientReviewsWithCompanyRequest(subdomain, clientId);
        setUserReviews(uReviews.responseModel)
        setIsOpenUserReviews(true)
    }

    const listRef = useRef();
    const sizeMap = useRef({});
    const setSize = useCallback((index, size) => {
        sizeMap.current = { ...sizeMap.current, [index]: size };
        listRef.current.resetAfterIndex(index);
    }, []);
    const getSize = index => sizeMap.current[index] || 50;
    const [windowWidth] = useWindowResize();

    React.useEffect(() => {
        fetchData();
    }, [])

    const Row = ({ index, setSize, windowWidth }) => {
        const rowRef = useRef();

        React.useEffect(() => {
            setSize(index, rowRef.current.getBoundingClientRect().height);
        }, [setSize, index, windowWidth]);

        return (
            <div
                ref={rowRef}
            >
                {reviewContent(filteredReviews[index], sourceCreateDate, otherReviewsOnClick)}
            </div>
        );
    };

    return (
        <>
            <div className='page'>
                {window.matchMedia && window.matchMedia("(max-width: 1200px)").matches ?
                    <Carousel
                        navButtonsAlwaysVisible
                        autoPlay
                        indicators={false}
                        animation="fade"
                        swipe
                        className="my-carousel"
                    >
                        {getCarouselList().map(item => item)}
                    </Carousel> :
                    <div className={`header ${userData?.companiesObj && getComboboxOption(userData?.companiesObj)?.length > 1 && 'header-with-sub-company'}`}>
                        {sourceLoading ? sourceCardSkeleton() : renderGetirCard()}
                        {sourceLoading ? sourceCardSkeleton() : renderYemekSepetiCard()}
                        {sourceLoading ? sourceCardSkeleton() : renderGoogleCard()}
                        {sourceLoading ? sourceCardSkeleton() : renderIyilestirCard()}
                        {/* {renderSikayetVarCard()} */}
                    </div>
                }
                <div className="body">
                    <div className='filter-div'>
                        <Button onClick={() => { navigate("/statistic") }} className='statistic-button' variant="contained" endIcon={<AutoGraphIcon style={{ fill: '#fff' }} />}>
                            İSTATİSTİKLERE GİT
                        </Button>
                        <div className='filter-header-div'>
                            <div className='filter-header'>
                                <span className='filter-text'>FİLTRELE</span>
                                <div className='total-review-cell'>
                                    <img alt='totalReviewCount' className='total-review-image source-star-filled' src={CommentFilledBlack} />
                                    <div className='review-count'>{filteredReviews.length}</div>
                                </div>
                            </div>
                            <div className='source-checkbox-div'>
                                <FormGroup >
                                    {getirData?.reviewsObj &&
                                        <div className='source-filter-div'>
                                            <div className='checkbox-row'>
                                                <FormControlLabel control={<Checkbox checked={isGetirChecked()} onChange={() => { chbOnChange(0) }} />} label="Getir" value={1} />
                                                <div className='checkbox-cell-row'>
                                                    <div className='checkbox-cell'>{getirRange[0]}</div>
                                                    <div className='checkbox-cell'>{getirRange[1]}</div>
                                                </div>
                                            </div>
                                            <div className='custom-slider getir-slider'>
                                                <Slider
                                                    getAriaLabel={() => 'Temperature range'}
                                                    value={getirRange}
                                                    disabled={!(selectedSources.length === 0 || selectedSources.some(i => i === 0))}
                                                    onChange={handleGetirChange}
                                                    valueLabelDisplay="auto"
                                                    max={5}
                                                    min={1}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {yemekSepetiData?.reviewsObj &&
                                        <div className='source-filter-div'>
                                            <div className='checkbox-row'>
                                                <FormControlLabel control={<Checkbox checked={isYemekSepetiChecked()} onChange={() => { chbOnChange(1) }} />} label="Yemek Sepeti" value={2} />
                                                <div className='checkbox-cell-row'>
                                                    <div className='checkbox-cell'>{yemekSepetiRange[0]}</div>
                                                    <div className='checkbox-cell'>{yemekSepetiRange[1]}</div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='custom-slider yemeksepeti-slider'>
                                                    <Slider
                                                        getAriaLabel={() => 'Temperature range'}
                                                        value={yemekSepetiRange}
                                                        disabled={!(selectedSources.length === 0 || selectedSources.some(i => i === 1))}
                                                        onChange={handleYemekSepetiChange}
                                                        valueLabelDisplay="auto"
                                                        max={10}
                                                        min={1}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {googleMapsData?.reviewsObj &&
                                        <div className='source-filter-div'>
                                            <div className='checkbox-row'>
                                                <FormControlLabel control={<Checkbox checked={isGoogleChecked()} onChange={() => { chbOnChange(2) }} />} label="Google Maps" value={3} />
                                                <div className='checkbox-cell-row'>
                                                    <div className='checkbox-cell'>{googleRange[0]}</div>
                                                    <div className='checkbox-cell'>{googleRange[1]}</div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='custom-slider google-slider'>
                                                    <Slider
                                                        getAriaLabel={() => 'Temperature range'}
                                                        value={googleRange}
                                                        disabled={!(selectedSources.length === 0 || selectedSources.some(i => i === 2))}
                                                        onChange={handleGoogleChange}
                                                        valueLabelDisplay="auto"
                                                        max={5}
                                                        min={1}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {(iyilestirData?.reviewsObj || newIyilestirData) &&
                                        <div className='source-filter-div'>
                                            <div className='checkbox-row'>
                                                <FormControlLabel control={<Checkbox checked={isIyilestirChecked()} onChange={() => { chbOnChange([3, 4]) }} />} label="İyileştir" value={0} />
                                                <div className='checkbox-cell-row'>
                                                    <div className='checkbox-cell'>{iyilestirRange[0]}</div>
                                                    <div className='checkbox-cell'>{iyilestirRange[1]}</div>
                                                </div>
                                            </div>
                                            <div className='custom-slider iyilestir-slider'>
                                                <Slider
                                                    getAriaLabel={() => 'Temperature range'}
                                                    value={iyilestirRange}
                                                    disabled={!(selectedSources.length === 0 || selectedSources.some(i => i === 3 || i === 4))}
                                                    onChange={handleIyilestirChange}
                                                    valueLabelDisplay="auto"
                                                    max={5}
                                                    min={1}
                                                />
                                            </div>
                                        </div>
                                    }
                                    <FormControlLabel control={
                                        <Checkbox checked={onlyCommentsSelected}
                                            onChange={isOnlyCommentsChecked}
                                            disabled={!(selectedSources.length === 0 || selectedSources.some(i => i === 3 || i === 4))}
                                        />}
                                        label="Görüş Bildirenler" value={0} />
                                    {SearchBox('Yorumlarda ara', (e) => setSearchTerm(e.target.value), showSearchCircularProgress)}
                                </FormGroup>
                            </div>
                        </div>
                        <div className='horizontal-line'></div>
                        {renderDatePicker()}
                    </div>
                    <div className='reviews-div'>
                        {sourceLoading ?
                            [1, 2, 3, 4, 5, 6, 7].map(() => {
                                return (
                                    reviewItemSkeleton()
                                )
                            })
                            :
                            <List
                                ref={listRef}
                                height={590}
                                width="100%"
                                itemCount={filteredReviews.length}
                                itemSize={getSize}
                                itemData={filteredReviews}
                            >
                                {({ index, style }) => (
                                    <div style={style}>
                                        <Row
                                            index={index}
                                            setSize={setSize}
                                            windowWidth={windowWidth}
                                        />
                                    </div>
                                )}
                            </List>
                        }
                    </div>
                </div>
            </div>
            <UserReviewsPopup
                isOpenUserReviews={isOpenUserReviews}
                setIsOpenUserReviews={setIsOpenUserReviews}
                userReviews={userReviews}
                sourceCreateDate={sourceCreateDate}
                otherReviewsOnClick={otherReviewsOnClick}
            />
        </>
    );
}

export default MainPage;