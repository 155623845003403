export function getOptions() {
    let token = localStorage.getItem("iyilestir-review-token")
    token = JSON.parse(token);
    return {
        'locale': 'tr',
        'platform-id': '2',
        "authorization": "x-access-token " + token.accessToken
    }
}
export function getPostOptions() {
    let token = localStorage.getItem("iyilestir-review-token")
    token = JSON.parse(token);
    return {
        'Content-Type': 'application/json',
        'locale': 'tr',
        'platform-id': '2',
        "authorization": "x-access-token " + token.accessToken
    }
}