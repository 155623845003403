import React from "react";
import { Autocomplete, IconButton, ListItemIcon, Menu, MenuItem, TextField } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { getLogo, postRequest } from "../helpers/api-service";

import IyilestirLogo from '../assets/images/logo_image.png';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Logout from '@mui/icons-material/Logout';
import LockReset from '@mui/icons-material/LockReset';
import Error from '@mui/icons-material/ErrorOutline';
import { getComboboxOption } from "../helpers/company-helper";
import ChangePassword from "../pages/changePassword";
import ReportError from "../pages/reportError";
import { getOptions } from "../constants/apiConstants";
import { BASE_URL } from "../constants/config";

function MainLayout({ children }) {
    const navigate = useNavigate();

    const [companyData, setCompanyData] = React.useState();
    const [companyLogo, setCompanyLogo] = React.useState();
    const [userData, setUserData] = React.useState();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isChangePassword, setIsChangePassword] = React.useState(false);
    const [isReportError, setIsReportError] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    let params = new URLSearchParams(window.location.search);
    let isIframe = params.get('isIframe');

    React.useEffect(() => {
        let token = localStorage.getItem("iyilestir-review-token")
        if (!token)
            navigate('/login')

        fetchData();
    }, [])

    const fetchData = async () => {
        setLoading(true);
        const options = getOptions();
        const option = {
            headers: options
        };
        const companyInfoJson = await fetch(BASE_URL + "/app/get/GetCompanyInfo", option);
        const companyData = await companyInfoJson.json();
        if (companyData.statusCode === 1103) {
            reLogin();
        } else if (companyData.statusCode !== 1000) {
            navigate('/login')
        }

        localStorage.setItem("iyilestir-subdomain", companyData.responseModel.subdomain)

        const userInfoJson = await fetch(BASE_URL + "/app/get/GetUserInfo", option);
        const userInfo = await userInfoJson.json();
        if (companyData.statusCode !== 1000) {
            navigate('/login')
        }

        if (companyData.responseModel) {
            document.title = "İyileştir Yönetici Paneli - " + companyData.responseModel.title;
            setCompanyData(companyData.responseModel);
            getLogo(BASE_URL + "/image?image=" + companyData.responseModel.logoUrl, option).then(blob => setCompanyLogo(URL.createObjectURL(blob)));
        }


        if (userInfo.responseModel) {
            setUserData(userInfo.responseModel)
            localStorage.setItem("iyilestir-user", JSON.stringify(userInfo.responseModel.companiesObj))
        }
        setLoading(false);

    }

    const reLogin = () => {
        const token = JSON.parse(localStorage.getItem("iyilestir-review-token"))
        const postData = { requestModel: { refreshToken: token.refreshToken } }

        postRequest(BASE_URL + "/api/insert/RefreshToken", postData).then(res => {
            if (res.statusCode === 1000) {
                localStorage.setItem("iyilestir-review-token", JSON.stringify(res.responseModel.token))
                window.location.reload();
            } else {
                navigate('/login')
            }
        })
    }

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const changePassword = () => {
        handleClose();
        setIsChangePassword(true);
    };

    const reportError = () => {
        handleClose();
        setIsReportError(true);
    }

    function logout() {
        handleClose();
        let token = localStorage.getItem("iyilestir-review-token")
        token = JSON.parse(token);
        fetch(BASE_URL + "/logout", {
            headers: {
                'locale': 'tr',
                'platform-id': '2',
                "authorization": "x-access-token " + token.accessToken
            }
        });
        localStorage.removeItem("iyilestir-review-token");
        navigate("/login")
    }

    async function handleBranchChanging(event, option) {
        const tokens = JSON.parse(localStorage.getItem("iyilestir-review-token"))
        const header = {
            'Content-Type': 'application/json',
            locale: 'tr',
            'platform-id': 1,
            authorization: 'x-access-token ' + tokens.accessToken
        }

        const data = { requestModel: { companyId: option.id } };
        const res = await fetch(BASE_URL +'/app/insert/ChangeCompany', {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)
        });
        const resModel = await res.json()
        localStorage.setItem("iyilestir-review-token", JSON.stringify(resModel.responseModel))
        await fetchData()
    }

    function getFirstSelectedBranch() {
        return getComboboxOption(userData?.companiesObj)?.filter((val) => val.subdomain === companyData?.subdomain)[0]
    }

    if (isIframe === 'true') {
        return (<>{children}</>)
    }

    return (
        !loading &&
        <>
            <nav className='navbar'>
                {companyLogo ? <img className='logo header-company-logo' alt='companyLogo' src={companyLogo}></img> : <div></div>}
                <img className='logo header-iyilestir-logo' onClick={() => { navigate("") }} alt='iyilestirLogo' src={IyilestirLogo}></img>
                <div>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                    >
                        <AccountCircle />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem key="changepasswordmenuitm" onClick={changePassword}>
                            <ListItemIcon key='sifredegistiricon' id="qweqw">
                                <LockReset fontSize="small" />
                            </ListItemIcon>Şifre Değiştir</MenuItem>

                        <MenuItem key="reporterrormenuitm" onClick={reportError}>
                            <ListItemIcon key='sorunbildiricon' id='asdas'>
                                <Error fontSize="small" />
                            </ListItemIcon>Sorun Bildir</MenuItem>
                        <MenuItem key="logoutmenuitm" onClick={logout}>
                            <ListItemIcon key='cikisyapicon' id="xcvx">
                                <Logout fontSize="small" />
                            </ListItemIcon>Çıkış Yap</MenuItem>
                    </Menu>
                </div>
            </nav>
            {getComboboxOption(userData?.companiesObj)?.length > 1 &&
                <div className='change-company-wrapper'>
                    <Autocomplete
                        disablePortal
                        className='change-company-autocomplete'

                        id="combo-box-demo"
                        options={getComboboxOption(userData?.companiesObj)}
                        onChange={handleBranchChanging}
                        getOptionLabel={(option) => option.title}
                        isOptionEqualToValue={(option, value) => option.subdomain === value.subdomain}
                        value={getFirstSelectedBranch() || null}
                        renderInput={(params) => <TextField {...params} label='Şube' />}
                    />
                </div>
            }
            {children}
            <ChangePassword setIsChangePassword={setIsChangePassword} isChangePassword={isChangePassword} />
            <ReportError setIsReportError={setIsReportError} isReportError={isReportError} userData={userData} />
        </>
    );
}

export default MainLayout;