import React from "react";

import CalendarIcon from '../assets/images/calendar.svg';
export const CustomInput = React.forwardRef((props, ref) => {
    return (
        <div onClick={props.onClick} className='datepicker-input'>
            <label ref={ref}>
                {props.value || props.placeholder}
            </label>
            <img style={{ position: 'absolute', right:22 }} src={CalendarIcon} alt='takvim icon'></img>
        </div>
    );
});