import '../login.css';
import React, { useRef } from 'react';
import { Alert, Button, Grid, Slide, Snackbar, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IyilestirBlackLogo from '../assets/images/iyilestir_svg.svg';
import IconButton from '@mui/material/IconButton';
import { postRequest } from '../helpers/api-service';
import { useNavigate } from 'react-router-dom';
import ResetPasswordTextField from '../components/reset-password';
import { BASE_URL } from '../constants/config';

function Login() {
    const navigate = useNavigate();
    const loginEmailRef = useRef();
    const loginPasswordRef = useRef();
    const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);

    function sendLoginRequest() {
        const email = loginEmailRef.current.value;
        const password = loginPasswordRef.current.value;
        const postData = { email, password }
        postRequest(BASE_URL + "/api/insert/login", postData).then(res => {
            if (res.statusCode === 1000) {
                window.dataLayer.push({
                    event: 'userData',
                    userId: email
                })
                localStorage.setItem("iyilestir-review-token", JSON.stringify(res.responseModel.token))
                navigate('/');
            } else {
                setOpenErrorSnackbar(true);
            }
        })

    }

    function TransitionLeft(props) {
        return <Slide {...props} direction="left" />;
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    return (
        <div className='login-body'>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={openErrorSnackbar}
                autoHideDuration={2000}
                onClose={() => setOpenErrorSnackbar(false)}
                TransitionComponent={TransitionLeft}
            >
                <Alert severity="error">Kullanıcı adı veya şifre yanlış!</Alert>
            </Snackbar>
            <div className='login-wrapper'>
                <div className='login-header'>
                    <img alt='login-logo' className='login-logo' src={IyilestirBlackLogo} />
                </div>
                <form autoComplete='on' className='login-form'>
                    <TextField
                        InputProps={{ disableUnderline: true }}
                        id="filled-basic1"
                        className='custom-input login-input'
                        label="Email"
                        type="username"
                        variant="filled"
                        inputRef={loginEmailRef}
                    />
                    <TextField
                        InputProps={{ // <-- This is where the toggle button is added.
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        id="filled-basic2"
                        className='custom-input password-input'
                        label="Parola"
                        type={showPassword ? "text" : "password"}
                        variant="filled"
                        inputRef={loginPasswordRef}
                    />
                    <Grid className='sign-in-button-wrapper'>
                        <Button className='sign-in-button' onClick={sendLoginRequest}>Giriş Yap</Button>
                    </Grid>
                </form>
                <ResetPasswordTextField />
            </div>
        </div>
    );
}

export default Login;