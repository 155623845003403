import ReactApexChart from 'react-apexcharts';

const MultipleBarChart = ({ data, xaxisCategories, tableTitle, columnValuePostfix }) => {
    const options = {
            chart: {
                type: 'bar',
                height: 400
            },
            plotOptions: {
                bar: {
                    borderRadius: 5,
                    horizontal: true,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            dataLabels: {
                enabled: true,
                offsetX: -20,
                formatter: function (val) {
                    return val? Number(val.toFixed(1)).toLocaleString() + " " + (columnValuePostfix ?? '') : '';
                },
                style: {
                    fontSize: '12px',
                    colors: ['#fff']
                }
            },
            stroke: {
                show: true,
                width: 1,
                colors: ['#fff']
            },
            tooltip: {
                shared: true,
                intersect: false,
                x: {
                    formatter: function (val) {
                        return 'Toplam Puanlama Sayısı: ' + data[0].totalRating[xaxisCategories.indexOf(val)];
                    },
                }
            },
            xaxis: {
                categories: xaxisCategories,
                labels: {show: true},
                axisTicks: {
                    show:true
                },
            },
            yaxis: {

            },
            title: {
                text: tableTitle,
                floating: true,
                offsetY: 0,
                align: "center",
                style: {
                    color: "#444"
                }
            },
    }

    return (
        <div id="chart">
            <ReactApexChart
                options={options}
                series={data}
                type='bar'
                height="400"
            />
        </div>
    );
}


export default MultipleBarChart