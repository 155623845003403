import { lastDayOfMonth, subDays, subMonths, subWeeks, subYears } from "date-fns";
import React from "react";
import tr from 'date-fns/locale/tr';
import ReactDatePicker from 'react-datepicker';

import { CustomDialog } from "./dialog/custom-dialog";
import { Button, Grid } from "@mui/material";
import { CustomInput } from "./datepickerInput";
import format from "date-fns/format";

const dateTypes = {
    custom: 0,
    yesterday: 1,
    last1Week: 2,
    currentMonth: 3,
    previousMonth: 4,
    last1Month: 5,
    last1Year: 6,
}

const textButtonStyle = { width: "100%", display: "flex", justifyContent: "start" }

export const CustomDatePicker = ({ isOpen, setIsOpen, onApprove, initialStartDate, initialEndDate }) => {
    const isMobile = window.matchMedia && window.matchMedia("(max-width: 1200px)").matches
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [shownStartDate, setShownStartDate] = React.useState(null);
    const [shownEndDate, setShownEndDate] = React.useState(null);
    const [selectedDateType, setSelectedDateType] = React.useState(dateTypes.custom);


    React.useEffect(() => {
        setShownStartDate(initialStartDate);
        setShownEndDate(initialEndDate);
    }, [])
    React.useEffect(() => {
        if (selectedDateType !== dateTypes.custom) {
            let now = Date.now();
            let start;
            setStartDate(null);
            setEndDate(null);
            if (selectedDateType === dateTypes.yesterday) {
                let yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);

                start = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate(), 0, 0, 0);
                now = start;
            } else if (selectedDateType === dateTypes.last1Week) {
                start = subWeeks(now, 1);
            } else if (selectedDateType === dateTypes.currentMonth) {
                now = lastDayOfMonth(now);
                const date = new Date(now)
                start = new Date(date.getFullYear(), date.getMonth(), 1);
            } else if (selectedDateType === dateTypes.previousMonth) {
                now = lastDayOfMonth(subMonths(now, 1));
                const date = new Date(now)
                start = new Date(date.getFullYear(), date.getMonth(), 1);
            } else if (selectedDateType === dateTypes.last1Month) {
                start = subMonths(now, 1);
            } else if (selectedDateType === dateTypes.last1Year) {
                start = subYears(now, 1);
            }
            console.log("start : ", start)
            console.log("now : ", now)
            onChange([start, now], selectedDateType)
        }
    }, [selectedDateType])

    const onChange = (dates, dateType) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (!dateType) {
            setSelectedDateType(dateTypes.custom);
        }
    };
    return (
        <div>
            <CustomInput
                value={`${shownStartDate ? format(shownStartDate, 'dd-MM-yyyy') : "Başlangıç"} -> ${shownEndDate ? format(shownEndDate, 'dd-MM-yyyy') : "Bitiş Tarihi"}`}
                onClick={() => setIsOpen(true)}
            />
            <CustomDialog
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                contentheight={!isMobile && 250}
                dialogMaxWidth={1000}
                approveText="Filtrele"
                onApprove={() => {
                    setShownStartDate(startDate);
                    setShownEndDate(endDate);
                    onApprove([startDate, endDate]);
                    setIsOpen(false);
                }}
                title="Tarih Filtrele"
                isTitleCenter
                content={
                    <Grid container spacing={2} direction="row">
                        <Grid item width={150}>
                            <Grid container alignItems="start" direction="column">
                                <Button style={textButtonStyle} sx={{ color: selectedDateType === 1 ? '#dc940a' : '#1976d2' }} onClick={() => setSelectedDateType(1)} variant="text">Dün</Button>
                                <Button style={textButtonStyle} sx={{ color: selectedDateType === 2 ? '#dc940a' : '#1976d2' }} onClick={() => setSelectedDateType(2)} variant="text">Son 7 gün</Button>
                                <Button style={textButtonStyle} sx={{ color: selectedDateType === 3 ? '#dc940a' : '#1976d2' }} onClick={() => setSelectedDateType(3)} variant="text">Bu ay</Button>
                                <Button style={textButtonStyle} sx={{ color: selectedDateType === 4 ? '#dc940a' : '#1976d2' }} onClick={() => setSelectedDateType(4)} variant="text">Geçen ay</Button>
                                <Button style={textButtonStyle} sx={{ color: selectedDateType === 5 ? '#dc940a' : '#1976d2' }} onClick={() => setSelectedDateType(5)} variant="text">Son 30 gün</Button>
                                <Button style={textButtonStyle} sx={{ color: selectedDateType === 6 ? '#dc940a' : '#1976d2' }} onClick={() => setSelectedDateType(6)} variant="text">Son 1 yıl</Button>
                            </Grid>
                        </Grid>
                        <Grid item >
                            <ReactDatePicker
                                monthsShown={isMobile ? 1 : 2}
                                isClearable={true}
                                dateFormat="dd-MM-yyyy"
                                locale={tr}
                                selected={startDate}
                                onChange={(dates) => onChange(dates)}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                selectsDisabledDaysInRange
                                inline
                            />
                        </Grid>
                    </Grid>
                }
            />
        </div>
    );
}