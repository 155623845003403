export function getComboboxOption(companies) {
    const branchesComboboxOptions = companies.map((company) => {
        return {
            value: company.title,
            title: company.title,
            subdomain: company.subdomain,
            label: company.title,
            id: company.id
        }
    })
    return branchesComboboxOptions;
}