import './App.css';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import StatisticPage from './pages/statisticPage';
import MainLayout from './layouts/mainLayout';
import Login from './pages/login';
import MainPage from './pages/mainPage';
import { useEffect } from 'react';
//import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-KXXN74L'
}
    
    //ReactGA.initialize('G-YJTD1J86PH')
function App() {
  useEffect(() => {
    TagManager.initialize(tagManagerArgs)    
  }, [])
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<MainLayout><Outlet /></MainLayout>}>
          <Route id='mainpageid' index path="/" element={<MainPage />} />
          <Route id='istatistikid' path="/statistic" element={<StatisticPage />} />
        </Route>
        <Route id='loginid' path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
