import { BASE_URL } from "../constants/config";

export const postRequest = (url, props = {}) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'locale': 'tr',
            'platform-id': '2'
        },
        body: JSON.stringify({ requestModel: props })
    };
    return fetch(url, requestOptions).then(response => response.json());
};

export const putRequest = (url, props = {}) => {
    let token = localStorage.getItem("iyilestir-review-token")
    token = JSON.parse(token);
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'locale': 'tr',
            'platform-id': '2',
            'authorization': 'x-access-token ' + token.accessToken
        },
        body: JSON.stringify({ requestModel: props })
    };
    return fetch(url, requestOptions).then(response => response.json());
};

// props => { title: "comapnyTitle", clientId: item.clientId }
export const clientReviewsWithCompanyRequest = (title, client) => {
    try {
        let token = localStorage.getItem("iyilestir-review-token")
        token = JSON.parse(token);
        const url = BASE_URL + "/app/insert/IyilestirOldReviewsClient";
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'locale': 'tr',
                'platform-id': '2',
                'authorization': 'x-access-token ' + token.accessToken
            },
            body: JSON.stringify({ requestModel: { title, client } })
        };
        return fetch(url, requestOptions).then(response => response.json());
    } catch (error) {
        console.error(error);
        return null;
    }
}

export function getLogo(url, options) {
    try {
        const src = url;
        return fetch(src, options).then(res => res.blob())
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const getStatisticsUsers = (props) => {
    try {
        let token = localStorage.getItem("iyilestir-review-token")
        token = JSON.parse(token);
        const url = BASE_URL + "/app/insert/StatisticsUsers";
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'locale': 'tr',
                'platform-id': '2',
                'authorization': 'x-access-token ' + token.accessToken
            },
            body: JSON.stringify({ requestModel: props })
        };
        return fetch(url, requestOptions).then(response => response.json());
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const getStatisticsViews = (props) => {
    try {
        let token = localStorage.getItem("iyilestir-review-token")
        token = JSON.parse(token);
        const url = BASE_URL + "/app/insert/StatisticsViews";
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'locale': 'tr',
                'platform-id': '2',
                'authorization': 'x-access-token ' + token.accessToken
            },
            body: JSON.stringify({ requestModel: props })
        };
        return fetch(url, requestOptions).then(response => response.json());
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const getStatisticsRealtimeViews = (props) => {
    try {
        let token = localStorage.getItem("iyilestir-review-token")
        token = JSON.parse(token);
        const url = BASE_URL + "/app/insert/StatisticsRealtimeViews";
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'locale': 'tr',
                'platform-id': '2',
                'authorization': 'x-access-token ' + token.accessToken
            },
            body: JSON.stringify({ requestModel: props })
        };
        return fetch(url, requestOptions).then(response => response.json());
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const contactWithUs = (props) => {
    try {
        let token = localStorage.getItem("iyilestir-review-token")
        token = JSON.parse(token);
        const url = BASE_URL + "/app/insert/contactwithus"
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'locale': 'tr',
                'platform-id': '2',
                'authorization': 'x-access-token ' + token.accessToken
            },
            body: JSON.stringify({ requestModel: props })
        }
        return fetch(url, requestOptions).then(response => response.json());
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const getIyilestirAverageRatings = (queryParams) => {
    try {
        const url = new URL(BASE_URL + '/app/get/GetRatingAverages')
        url.search = new URLSearchParams(queryParams).toString();
        let token = localStorage.getItem("iyilestir-review-token")
        token = JSON.parse(token);
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'locale': 'tr',
                'platform-id': '2',
                'authorization': 'x-access-token ' + token.accessToken
            },

        }
        return fetch(url, requestOptions).then(response => response.json());
    } catch (error) {
        console.error(error);
        return null;
    }
}