import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide } from "@mui/material";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function BootstrapDialogTitle(props) {
    const { children, onClose, isTitleCenter, ...other } = props;

    return (
        <DialogTitle display="flex" justifyContent={isTitleCenter ? "center" : "start"} sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

export const CustomDialog = ({ title, approveText, isOpen, setIsOpen, onApprove, content, contentheight, dialogMaxWidth, isTitleCenter }) => {
    return (
        <Dialog
            sx={{ height: "auto" }}
            open={isOpen}
            TransitionComponent={Transition}
            keepMounted
            maxWidth={String(dialogMaxWidth)}

            onClose={() => setIsOpen(false)}
            aria-describedby="alert-dialog-slide-description"
        >
            {title &&
                <BootstrapDialogTitle
                    isTitleCenter={isTitleCenter}

                    onClose={() => setIsOpen(false)}
                >{title}
                </BootstrapDialogTitle>
            }
            <DialogContent
                sx={{ height: contentheight }}
            >
                {content}
            </DialogContent>
            {onApprove &&
                <DialogActions>
                    <Button className='sign-in-button' type='submit' onClick={onApprove}>{approveText}</Button>
                </DialogActions>}
        </Dialog>
    );
}