import { Paper, Box, Grid } from '@mui/material';

const StatisticInfoCard = ({ title, value, Icon }) => {
    return (
        <Grid item md={3} xs={12}>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1,
                        height: 128,
                    },
                }}
            >
                <Paper elevation={4} className='paper-user-retention'>
                    <div title='ActiveUser' className='paper-user-retention-text-title'>{title}</div>
                    <div title='rate' className='paper-user-retention-text'>{Icon && <Icon fontSize='large'></Icon>}{value}</div>
                </Paper>
            </Box>
        </Grid>
    )
}

export default StatisticInfoCard;