import '../styles/statistic.css';

import React from 'react';
import { getIyilestirAverageRatings, getStatisticsUsers, getStatisticsViews, getStatisticsRealtimeViews } from '../helpers/api-service';
import { addYears, subDays } from "date-fns";
import { format } from 'date-fns';
import BarChart from '../components/charts/barChart';
import { Grid } from '@mui/material';
import { prepareTableDensityData, prepareUserInfo, prepareUserInfoDaily, prepareUserInfoHourly, prepareRealtimeViews, prepareTotalViews, preparePeriodicRatingData } from '../helpers/statistic-helper';
import StatisticInfoCard from '../components/card/statisticInfoCard';
import { Loop, Person, Star } from '@mui/icons-material';
import MultipleBarChart from '../components/charts/multipleColumnBarChart';
import { CustomDatePicker } from '../components/custom-date-picker';

function dateFormat(date, formatStr) {
    return format(new Date(date), formatStr ?? 'yyyy-MM-dd')
}

function StatisticPage() {
    const [dateRange, setDateRange] = React.useState([subDays(new Date(), 30), new Date()]);
    const [tableDensityList, setTableDensityList] = React.useState();
    const [dailyDensityList, setDailyDensityList] = React.useState();
    const [hourlyDensityList, setHourlyDensityList] = React.useState();
    const [userRetention, setUserRetention] = React.useState();
    const [realtimeViews, setRealtimeViews] = React.useState();
    const [totalViews, setTotalViews] = React.useState();
    const [iyilestirAverageRatings, setIyilestirAverageRatings] = React.useState();
    const [iyilestirAverageRatingsWeekly, setIyilestirAverageRatingsWeekly] = React.useState();
    const [tableDensityData, setTableDensityData] = React.useState();
    const [userData, setUserData] = React.useState();
    const [dailyDensityData, setDailyDensityData] = React.useState();
    const [hourlyDensityListData, setHourlyDensityListData] = React.useState();
    const [realtimeViewsData, setRealtimeViewsData] = React.useState();
    const [totalViewsData, setTotalViewsData] = React.useState();
    const [iyilestirAverageRatingsData, setIyilestirAverageRatingsData] = React.useState();
    const [iyilestirAverageRatingsDataWeekly, setIyilestirAverageRatingsDataWeekly] = React.useState();
    const [isDateDialogOpen, setIsDateDialogOpen] = React.useState(false);

    //! fetch data zaten aşağıda bir tane useeffect'te çağırılıyor. o yüzden burda tekrar çağırmaya gerek yok.
    // React.useEffect(() => {
    //     fetchData();
    // }, [])
    const fetchData = async () => {
        setTableDensityList(null)
        let sDate, eDate
        if (!dateRange[0] || !dateRange[1]) {
            sDate = addYears(new Date(), -10);
            eDate = addYears(new Date(), 10);
        } else {
            sDate = dateRange[0];
            eDate = dateRange[1];
        }
        sDate = dateFormat(sDate)
        eDate = dateFormat(eDate)
        const dailyDensityDataEndDate = dateFormat(new Date()) === eDate ? dateFormat(subDays(new Date(), 1)) : eDate;
        const hourlyDensityDataStartDate = dateFormat(new Date()) === eDate ? dateFormat(subDays(Date.parse(eDate), 1)) : eDate;

        getStatisticsUsers({ useTag: true, dateRanges: [{ "startDate": sDate, "endDate": eDate }] }).then(res => {
            setTableDensityData(res);
        })
        getStatisticsUsers({ useTag: false, dateRanges: [{ "startDate": sDate, "endDate": eDate }] }).then(res => {
            setUserData(res);
        })
        getStatisticsUsers({ useTag: false, period: 'daily', dateRanges: [{ "startDate": sDate, "endDate": dailyDensityDataEndDate }] }).then(res => {
            setDailyDensityData(res);
        })
        getStatisticsUsers({ useTag: false, period: 'hourly', dateRanges: [{ "startDate": hourlyDensityDataStartDate, "endDate": hourlyDensityDataStartDate }] }).then(res => {
            setHourlyDensityListData(res);
        })
        getStatisticsRealtimeViews().then(res => {
            setRealtimeViewsData(res);
        })
        getStatisticsViews({ dateRanges: [{ "startDate": sDate, "endDate": eDate }] }).then(res => {
            setTotalViewsData(res);
        })

        getIyilestirAverageRatings({ startDate: sDate, endDate: eDate }).then(res => {
            setIyilestirAverageRatingsData(res);
        })

        getIyilestirAverageRatings({ startDate: sDate, endDate: eDate, period: 'weekly' }).then(res => {
            setIyilestirAverageRatingsDataWeekly(res);
        })
    }

    React.useEffect(() => {
        if (!tableDensityData?.responseModel)
            return;
        const [totalTableDensity, reviewList, menuList] = prepareTableDensityData(tableDensityData.responseModel.rows);
        setTableDensityList(totalTableDensity)
    }, [tableDensityData])

    React.useEffect(() => {
        if (!userData?.responseModel)
            return;
        const userInfos = prepareUserInfo(userData.responseModel?.rows);
        setUserRetention(userInfos)
    }, [userData])

    React.useEffect(() => {
        if (!dailyDensityData?.responseModel)
            return;
        const dailyDensity = prepareUserInfoDaily(dailyDensityData.responseModel?.rows);
        setDailyDensityList(dailyDensity)
    }, [dailyDensityData])

    React.useEffect(() => {
        if (!hourlyDensityListData?.responseModel)
            return;
        const hourlyDensity = prepareUserInfoHourly(hourlyDensityListData.responseModel?.rows);
        setHourlyDensityList(hourlyDensity)
    }, [hourlyDensityListData])

    React.useEffect(() => {
        if (!realtimeViewsData?.responseModel)
            return;
        const realtimeViewsTemp = prepareRealtimeViews(realtimeViewsData.responseModel?.rows);
        setRealtimeViews(realtimeViewsTemp)
    }, [realtimeViewsData])

    React.useEffect(() => {
        if (!totalViewsData?.responseModel)
            return;
        const totalViewsTemp = prepareTotalViews(totalViewsData.responseModel?.rows);
        setTotalViews(totalViewsTemp)
    }, [totalViewsData])

    React.useEffect(() => {
        if (!iyilestirAverageRatingsData?.responseModel)
            return;
        setIyilestirAverageRatings(iyilestirAverageRatingsData.responseModel)
    }, [iyilestirAverageRatingsData])

    React.useEffect(() => {
        if (!iyilestirAverageRatingsDataWeekly?.responseModel && !iyilestirAverageRatingsDataWeekly?.responseModel[0]?.ratings.length > 0)
            return;
        setIyilestirAverageRatingsWeekly(preparePeriodicRatingData(iyilestirAverageRatingsDataWeekly.responseModel))
    }, [iyilestirAverageRatingsDataWeekly])

    React.useEffect(() => {
        if ((dateRange[0] && dateRange[1]) || (!dateRange[0] && !dateRange[1])) {
            fetchData()
        }
    }, [dateRange])

    function renderDatePicker() {
        return (
            <div className='date-picker-wrapper'>
                <CustomDatePicker
                    initialStartDate={dateRange[0]}
                    initialEndDate={dateRange[1]}
                    isOpen={isDateDialogOpen}
                    setIsOpen={setIsDateDialogOpen}
                    onApprove={(update) => { setDateRange(update); }}
                />
            </div>
        )
    }

    function renderBasicBarChart(color, list, nameStr, valueStr, limit, tableTitle, bottomText, valueDescription, columnValuePostfix) {
        const data = []
        let index = list.length <= limit ? 0 : list.length - limit
        for (index; index < list.length; index++) {
            const element = list[index];
            data.push({
                value: element[valueStr],
                name: element[nameStr]
            })
        }
        return (
            <Grid item md={6} xs={12} className='chart-wrapper'>
                <BarChart data={data} tableTitle={tableTitle} bottomText={bottomText} valueDescription={valueDescription} columnValuePostfix={columnValuePostfix} color={color} ></BarChart>
            </Grid>
        )
    }
    function renderBasicBarChartHourlyDensity(color, list, nameStr, valueStr, limit, tableTitle, bottomText, valueDescription, columnValuePostfix) {
        const data = []
        let index = list.length <= limit ? 0 : list.length - limit
        for (index; index < list.length; index++) {
            const element = list[index];
            data.push({
                value: element[valueStr],
                name: element[nameStr]
            })
        }
        return (
            <Grid item md={6} xs={12} className='chart-wrapper'>
                <BarChart data={data} tableTitle={tableTitle} bottomText={bottomText} valueDescription={valueDescription} columnValuePostfix={columnValuePostfix} color={color}></BarChart>
            </Grid>
        )
    }

    function renderGroupBarChart(list, tableTitle, columnValuePostfix) {
        return (
            <Grid item md={6} xs={12} className='chart-wrapper'>
                <MultipleBarChart data={list.series} xaxisCategories={list.xaxis} tableTitle={tableTitle} columnValuePostfix={columnValuePostfix}></MultipleBarChart>
            </Grid>
        )
    }

    function renderStatisticInfoCard(title, value, Icon) {
        return (
            <StatisticInfoCard
                title={title}
                value={value}
                Icon={Icon}
            >
            </StatisticInfoCard>
        )
    }

    function renderAverageRatingCards(rating) {
        return (
            <>
                {rating.ratings.map(i => renderStatisticInfoCard(i.name, i.score.toFixed(2), Star))}
                {renderStatisticInfoCard('Toplam Puanlama Sayısı', rating.totalRating)}
                {renderStatisticInfoCard('Ortalama Puan', rating.averageRating.toFixed(2), Star)}
            </>
        )
    }

    return (
        <>
            <div className='statistic-header'>
                <div className='statistic-header-text'>Detaylı Veri Analizleri</div>
                <div className='statistic-desctiption-text'>Yoğun saatlerde personel sayınızı artırarak daha iyi hizmet verebilirsiniz.</div>
                <div className='statistic-desctiption-text'>Tekrar gelen müşteri oranınızı %15’in üstüne çıkarmak için harika hamleler yapın.</div>
                <div className='statistic-desctiption-text'>Ekibinizi tebrik edin ve şikayetleri hep birlikte en özenli ve hızlı şekilde çözelim.</div>
                <div className='statistic-desctiption-text'><b>NOT: </b>Geri bildirim verileri 05 Aralık, menü verileri ise 18 aralıktan tarihlerinden sonradır. Yeni verilerin grafiklerde görünmeleri 24 saati bulabilir.</div>
            </div>
            <div className='body'>
                <div className='filter-div'>
                    <div className='filter-header-div'>
                        <div className='filter-header'>
                            <span className='filter-text'>FİLTRELE</span>
                        </div>
                        <div className='source-checkbox-div'>

                        </div>
                    </div>
                    <div className='horizontal-line'></div>
                    {renderDatePicker()}
                </div>
                <div className='right-side'>
                    <Grid container className="statistic-cards-container">
                        {realtimeViews && renderStatisticInfoCard("Son 30 Dakikada Görüntülenme", realtimeViews)}
                        {totalViews && renderStatisticInfoCard("Toplam Görüntülenme", totalViews)}
                        {userRetention && userRetention.length > 0 && renderStatisticInfoCard(userRetention[1].name, `${userRetention[1].rate}%`, Person)}
                        {userRetention && userRetention.length > 0 && renderStatisticInfoCard(userRetention[2].name, `${userRetention[2].rate}%`, Loop)}
                        {iyilestirAverageRatings && iyilestirAverageRatings[0].data[0].ratings.length > 0 && renderAverageRatingCards(iyilestirAverageRatings[0].data[0])}
                    </Grid>
                    <Grid container spacing={4}>
                        {dailyDensityList && dailyDensityList.length > 0 && renderBasicBarChart('#E91E63', dailyDensityList, "date", "value", 9, 'Günlere Göre Yoğunluk', 'Gün/Ay', 'Kişi sayısı', "Kişi")}
                        {hourlyDensityList && hourlyDensityList.length > 0 && renderBasicBarChartHourlyDensity('#9C27B0', hourlyDensityList, "date", "value", 24, 'Saate Göre Yoğunluk', 'Saat/Gün', 'Kişi sayısı', null)}
                        {tableDensityList && tableDensityList.length > 0 && renderBasicBarChart('#F44336', tableDensityList, "name", "value", 5, 'En Yoğun Masalar', 'Masa Numarası', 'Kişi sayısı', 'Kişi')}
                        {iyilestirAverageRatingsWeekly && iyilestirAverageRatingsWeekly?.series?.length > 0 && renderGroupBarChart(iyilestirAverageRatingsWeekly, 'Haftalık Puanlamalar', 'Puan')}
                    </Grid>
                </div>
            </div>
        </>
    );
}

export default StatisticPage;